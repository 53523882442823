<template>
  <section class="sitemap">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Open Positions</h1>
          <p>Some opportunities for you to explore</p>
        </div>
        <div class="col-md-12">
          <div class="career-box" v-for="(career, key) in careers" :key="key">
            <h1>{{ career.jobtitle }}</h1>
            <p>{{ career.description }}</p>
            <div class="d-flex">
              <h5 class="careerslocation">
                <ICON_Location
                  :size="icon_size"
                  :color="footer_color"
                  class="loactionicon"
                />{{ career.location }}
              </h5>
              <h5 class="careerslocation">
                <ICON_Clock
                  :size="icon_size"
                  :color="footer_color"
                  class="loactionicon"
                />{{ career.date }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ICON_Location from "@/assets/icons/Location.vue";
import ICON_Clock from "@/assets/icons/Clock.vue";
import axios from "axios";
export default {
  components: { ICON_Location, ICON_Clock },
  data() {
    return {
      footer_color: "black",
      icon_size: 30,
      lang: "en",
      careers: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },

  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/careers/collections/careers/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.careers = [];
            return;
          } else if (data.data.length == 0) {
            this.careers = [];
            return;
          }
          this.careers = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.sitemap {
  padding: 130px 40px 50px 40px;
}
@media only screen and (min-width: 0px) and (max-width: 998px) {
  .sitemap {
    padding: 40px !important;
  }
  .careerslocation {
    padding: 5px 5px !important;
    font-size: 18px;
  }
}
.careerslocation {
  padding: 10px 20px;
}
.careerslocation svg {
  position: relative;
  top: 3px;
}
.career-box {
  margin: 20px 0px;
  border-radius: 15px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  padding: 20px;
}
.career-box:hover {
  cursor: pointer;
  border-color: #0880e8 !important;
}
</style>
