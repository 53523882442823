<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 2.66667C8.63622 2.66667 2.66669 8.63621 2.66669 16C2.66669 23.3637 8.63622 29.3333 16 29.3333C23.3638 29.3333 29.3334 23.3637 29.3334 16C29.3334 12.4638 27.9286 9.07241 25.4282 6.57191C22.9276 4.07143 19.5363 2.66667 16 2.66667ZM16 26.6667C10.109 26.6667 5.33335 21.8911 5.33335 16C5.33335 10.109 10.109 5.33334 16 5.33334C21.8911 5.33334 26.6667 10.109 26.6667 16C26.6667 18.8289 25.5428 21.5421 23.5426 23.5425C21.5422 25.5428 18.829 26.6667 16 26.6667ZM17.1734 8.66667V15.52L22.0134 20.36C22.2691 20.6237 22.2691 21.0429 22.0134 21.3067L21.3067 22.0133C21.043 22.2691 20.6238 22.2691 20.36 22.0133L15.0267 16.68C14.9046 16.5545 14.8332 16.3883 14.8267 16.2133V8.66667C14.8267 8.29849 15.1252 8.00001 15.4934 8.00001H16.5067C16.8748 8.00001 17.1734 8.29849 17.1734 8.66667Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
